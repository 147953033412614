var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-form',{ref:"form",staticClass:"d-flex justify-space-between",model:{value:(_vm.roleValid),callback:function ($$v) {_vm.roleValid=$$v},expression:"roleValid"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-text-field',{staticClass:"mr-5 text-h6 px-0",attrs:{"rules":_vm.roleRules,"solo":"","single-line":"","dense":"","outlined":hover,"flat":"","placeholder":"Nhập tên vai trò"},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}})]}}])}),_c('div',[(_vm.role.id)?_c('v-btn',{staticClass:"font-weight-bold mr-4",attrs:{"depressed":"","color":"grey darkend-1","rounded":"","icon":"","disabled":_vm.roleStatusRequest === 'loading-deleteRole'},on:{"click":function($event){return _vm.deleteRole()}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{staticClass:"font-weight-bold grey--text text--darken-3 rounded-lg mr-4",attrs:{"color":"grey lighten-3","depressed":""},on:{"click":function($event){return _vm.resetRole()}}},[_vm._v(" Hủy ")]),_c('v-btn',{staticClass:"font-weight-bold rounded-lg",attrs:{"depressed":"","color":"primary","disabled":!_vm.roleValid},on:{"click":function($event){_vm.roleStatusRequest === 'loading-updateRole' ||
          _vm.roleStatusRequest === 'loading-createRole'
            ? null
            : _vm.role.id
            ? _vm.updateRole()
            : _vm.createRole()}}},[_vm._v(" "+_vm._s(_vm.role.id ? "Lưu" : "Thêm")+" ")])],1)],1),_c('v-alert',{staticClass:"text-body-2",attrs:{"color":"orange","text":"","dense":""}},[_vm._v(" Tùy chỉnh quyền hạn của vai trò bằng cách tích chọn các quyền bên dưới. ")]),_c('v-treeview',{staticClass:"treeview--scrollable",attrs:{"selectable":"","selected-color":"primary","items":_vm.permissions},model:{value:(_vm.role.permissions),callback:function ($$v) {_vm.$set(_vm.role, "permissions", $$v)},expression:"role.permissions"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }