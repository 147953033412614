import { render, staticRenderFns } from "./RoleDetail.vue?vue&type=template&id=f1993422&scoped=true&"
import script from "./RoleDetail.vue?vue&type=script&lang=js&"
export * from "./RoleDetail.vue?vue&type=script&lang=js&"
import style0 from "./RoleDetail.vue?vue&type=style&index=0&id=f1993422&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1993422",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAlert,VBtn,VForm,VHover,VIcon,VTextField,VTreeview})
