<template>
  <div style="height: 100%">
    <v-form ref="form" v-model="roleValid" class="d-flex justify-space-between">
      <v-hover v-slot:default="{ hover }">
        <v-text-field
          class="mr-5 text-h6 px-0"
          v-model="role.name"
          :rules="roleRules"
          solo
          single-line
          dense
          :outlined="hover"
          flat
          placeholder="Nhập tên vai trò"
        ></v-text-field>
      </v-hover>

      <div>
        <v-btn
          v-if="role.id"
          class="font-weight-bold mr-4"
          depressed
          color="grey darkend-1"
          rounded
          icon
          :disabled="roleStatusRequest === 'loading-deleteRole'"
          @click="deleteRole()"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn
          v-else
          class="font-weight-bold grey--text text--darken-3 rounded-lg mr-4"
          color="grey lighten-3"
          depressed
          @click="resetRole()"
        >
          Hủy
        </v-btn>
        <v-btn
          class="font-weight-bold rounded-lg"
          depressed
          color="primary"
          :disabled="!roleValid"
          @click="
            roleStatusRequest === 'loading-updateRole' ||
            roleStatusRequest === 'loading-createRole'
              ? null
              : role.id
              ? updateRole()
              : createRole()
          "
        >
          {{ role.id ? "Lưu" : "Thêm" }}
        </v-btn>
      </div>
    </v-form>
    <v-alert class="text-body-2" color="orange" text dense>
      Tùy chỉnh quyền hạn của vai trò bằng cách tích chọn các quyền bên dưới.
    </v-alert>
    <v-treeview
      class="treeview--scrollable"
      selectable
      selected-color="primary"
      :items="permissions"
      v-model="role.permissions"
    ></v-treeview>
  </div>
</template>

<script>
export default {
  props: {
    currentRoleIndex: Number
  },
  data: () => ({
    roleRules: [value => !!value || "Bạn chưa nhập tên vai trò."],
    roleValid: true,
    selectedItems: []
  }),
  computed: {
    permissions() {
      return this.$store.getters["ROLE/permissions"];
    },
    role() {
      return this.$store.getters["ROLE/role"];
    },
    roles() {
      return this.$store.getters["ROLE/roles"];
    },
    roleStatusRequest() {
      return this.$store.getters["ROLE/statusRequest"];
    }
  },
  methods: {
    async createRole() {
      await this.validateRole();

      if (this.roleValid) {
        await this.$store.dispatch("ROLE/createRole", this.role);
        if (this.roleStatusRequest.value === "success-createRole") {
          this.$emit("updateCurrentRoleIndex", this.roles.length - 1);
          // Alert
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Đã thêm vai trò"
            }
          });
        }
      }
    },
    async deleteRole() {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa vai trò <strong>${this.role.name}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("ROLE/deleteRole", this.role.id);
              if (this.roleStatusRequest.value === "success-deleteRole") {
                this.$emit("updateCurrentRoleIndex", null);
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa vai trò"
                  }
                });
              }
            }
          }
        }
      });
    },
    resetRole() {
      this.$emit("updateCurrentRoleIndex", null);
      this.$store.dispatch("ROLE/resetRole");
    },
    async updateRole() {
      await this.validateRole();

      if (this.roleValid) {
        await this.$store.dispatch("ROLE/updateRole", {
          role: this.role,
          roleIndex: this.currentRoleIndex
        });
        if (this.roleStatusRequest.value === "success-updateRole") {
          // Alert
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Đã lưu thay đổi"
            }
          });
        }
      }
    },
    validateRole() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.treeview--scrollable {
  max-height: calc(100% - 113px);
  overflow-y: scroll;
}
</style>
