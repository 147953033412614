<template>
  <v-list class="py-0 grey lighten-3" style="height: 100%">
    <h3 class="font-weight-bold mb-4">Vai trò ({{ roles.length }})</h3>
    <v-list-item
      class="rounded-lg white mb-3 role-list--item"
      :class="role.id ? 'role-add--disable' : null"
      link
      @click="role.id ? resetRole() : null"
    >
      <v-list-item-content>
        <v-icon>mdi-plus</v-icon>
      </v-list-item-content>
    </v-list-item>

    <v-list class="list--scrollable py-0 grey lighten-3">
      <v-list-item-group
        v-model="commputedCurrentRoleIndex"
        active-class="primary white--text font-weight-bold"
        color="primary"
      >
        <v-list-item
          class="role-list--item rounded-lg white mb-3"
          v-for="(item, index) in roles"
          :key="index"
          @click="selectRole(item, index)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-list>
</template>

<script>
export default {
  props: {
    currentRoleIndex: Number
  },
  computed: {
    commputedCurrentRoleIndex: {
      get() {
        return this.currentRoleIndex;
      },
      set(val) {
        this.$emit("updateCurrentRoleIndex", val);
      }
    },
    role() {
      return this.$store.getters["ROLE/role"];
    },
    roles() {
      return this.$store.getters["ROLE/roles"];
    }
  },
  methods: {
    resetRole() {
      this.$emit("updateCurrentRoleIndex", null);
      this.$store.dispatch("ROLE/resetRole");
    },
    selectRole(role, index) {
      if (index === this.currentRoleIndex) return;

      this.$store.dispatch("ROLE/setRole", { ...role });
    }
  }
};
</script>

<style lang="scss" scoped>
.list--scrollable {
  max-height: calc(100% - 100px);
  overflow-y: scroll;
}
.role-list--item {
  &::before {
    border-radius: 0.5rem;
  }
}
</style>
