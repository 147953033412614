<template>
  <v-row class="pr-5">
    <v-col class="height--fixed px-5 py-0" cols="3">
      <list-roles
        :current-role-index="currentRoleIndex"
        @updateCurrentRoleIndex="currentRoleIndex = $event"
      ></list-roles>
    </v-col>
    <v-col class="height--fixed white py-4 px-5 rounded" cols="9">
      <role-detail
        :current-role-index="currentRoleIndex"
        @updateCurrentRoleIndex="currentRoleIndex = $event"
      ></role-detail>
    </v-col>
  </v-row>
</template>

<script>
import ListRoles from "./components/ListRoles";
import RoleDetail from "./components/RoleDetail";

export default {
  components: {
    ListRoles,
    RoleDetail
  },
  data() {
    return {
      currentRoleIndex: null
    };
  },
  created() {
    this.$store.dispatch("ROLE/getPermissions");
    this.$store.dispatch("ROLE/getRoles");
  }
};
</script>

<style lang="scss" scoped>
.height--fixed {
  max-height: calc(100vh - 64px - 53px - 24px);
  overflow: hidden;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 24px);
  }
}
</style>
